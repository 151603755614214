div#welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
}
.welcome-wrapper {
    width: 580px;
    background-color: var(--transparent-white);
    margin: 20px;
    padding: 20px;
    filter: drop-shadow(-2px 6px 6px var(--world-shadow-color));
    text-align: center;
    border-style: solid;
    border-width: 4px;
    border-image-slice: 2;
    border-image-width: 2;
    border-image-repeat: stretch;
    border-image-outset: 1;
    background-clip: padding-box;
}
.night .welcome-wrapper {
    background-color: var(--transparent-black);
    border-color: black;
    border: none;
    border-radius: 4px;
}
.welcome-header-wrapper {
    display: flex;
    align-items: center;
}
.welcome-wrapper label, .ui.toggle.checkbox label {
    position: absolute;
    z-index: -1;
    font-size: 0.1px;
}
.avatar-head {
    width: 126px;
    height: 126px;
    background-color: white;
    border-radius: 50%;
    padding-left: 10px;
    margin-right: 20px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, #235fd5, #b478f9, #f978dd, #fb6a6a, #ff953a);
    background-size: 400% 400%;
    animation: gradient 10s ease-in-out infinite;
    overflow: hidden;
}
.avatar-head img {
    width: 120px;
    margin-left: -10px;
    margin-top: 10px;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
#welcome h2 {
    font-size: 14px;
}
div#welcome p {
    font-size: 9px;
}
#welcome button, #welcome input{
    font-family: 'VG';
    font-size: 10px;
}
#welcome input[type="submit"], #welcome button {
    width: 48%;
    margin: 10px 1%;
}
div#world-mode {
    position: absolute;
    right: 20px;
}
div#world-mode, .ui.toggle.checkbox {
    width: 90px;
}
div#world-mode i.moon.icon, div#world-mode i.sun.icon {
    color: #ecc449;
    font-size: 20px;
    position: absolute;
}
div#world-mode i.moon.icon{
    left: 60px;
}
div#world-mode i.sun.icon {
    left: -30px;
}
span small {
    font-size: 6px;
    line-height: 10px;
    color: #333;
}
@media screen and (max-width: 598px){
    div#welcome {
        height: calc(100vh - 50px);
    }
    #welcome input[type="submit"], #welcome button {
        width: 100%;
        margin: 4px 1%;
    }
    #welcome input {
        margin-bottom: 10px;
    }
    div#welcome p {
        font-size: 8px;
    }
}

@media screen and (max-width: 440px){
    .welcome-wrapper {
        width: 340px;
    }
    .avatar-head {
        width: 90px;
        height: 90px;
    }
    .avatar-head img {
        width: 100%;
    }
    #welcome h1 {
        font-size: 20px;
    }
    #welcome h2 {
        font-size: 10px;
    }
}