@font-face {
    font-family: 'VG';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nbivN04w.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-display: swap;
}
@font-face {
    font-family: 'VG';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nVivM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}
:root{
    --main-text-color: #111;
    --main-night-text-color: #ddd;
    --green: #39904c;
    --green-night: #005527;
    --world-shadow: -0.4rem 0.4rem 0.3rem 0 rgb(40 40 40 / 35%);
    --world-shadow-color: rgb(40 40 40 / 35%);
    --character-shadow: -6px 9px 14px 8px rgb(0 0 0 / 30%);
    --transparent-white: #ffffffdf; /* d7f4fedd */
    --transparent-black: #00000099; /* d7f4fedd */
}
html{
    font-size: 0.675;
}
body{
    font-family: "VG";
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVRYR9WXURLDIAhE6/0PbSdOtUpcd1Gnpv1KGpTHBpCE1/cXq+vrMph7dGvXZTtpfW10DCA5jrH1H0Jhs5E0hnZdCR+vb5S8Nn8mQCeS9BdSalYJqMBjAGzq59xAESN7VFVUgV8AZB/dZBR7QTFDCqGquvUBVVoEtgIwpQRzmANSFHgWQKExHdIrPeuMvQNDarXe6nC/AutgV3JW+6bgqQLeV8FekRtgV+ToDKEKnACYKsfZjjkam7a0ZpYTytwmgainpC3HvwBocgKOxqRjehoR9DFKNFYtOwCGYCszobeCbl26N6yyQ6g8X/Wex/rBPsNEV6qAMaJPMynIHQCoSqS9JSMmwef51LflTgCRszU7DvAGiV6mHWfsaVUAAAAASUVORK5CYII=), auto;
}
a, .menu-wrapper > a.item, a > i, button, .ui.button, #player-controls, .portfolio-nav{
    font-family: 'VG';
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0, pointer;
}
#mawmawmaw {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    filter: blur(.4px);
    background-color: var(--green);
}
#mawmawmaw.night{
    background-color: var(--green-night);
}
h1,h2,h3,h4,p{
    font-family: 'VG';
    font-weight: 100;
    color: var(--main-text-color);
}
.night h1,.night h2,.night h3,.night h4,.night p{
    color: var(--main-night-text-color);
}
a{
    font-weight: bold;
    color: var(--main-text-color);
}
button{
    color: var(--main-text-color);
}
.night a, .night button{
    color: var(--main-night-text-color);
}
h1{
    font-size: 30px;
}
h2{
    font-size: 16px;
}
h3{
    font-size: 12px;
}
p, ul li, a{
    font-size: 10.5px;
}
button.ui.button.blue {
    font-family: 'VG';
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
}
#player-controls {
    font-family: 'VG';
    position: fixed;
    bottom: 5px;
    right: 5px;
    background-color: rgba(0,0,0,0.6);
    padding: 0 10px 10px;
    color: white;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    display: none;
    animation: pulse 1s ease-in-out infinite;
}
@keyframes pulse {
    0%{
        zoom: 1;
    }
    100%{
        zoom: 1.04;
    }
}
div#player-controls img {
    display: inherit;
    width: 80px;
    margin: 0 auto;
}
.blue,.primary{
    background-color: #003fc7db !important;
}
.blue:hover,.primary:hover{
    background-color: #0437a5db !important;
}
.button, input, textarea {
    border-style: solid !important;
    border-width: 4px !important;
    border-image-slice: 2 !important;
    border-image-width: 2 !important;
    border-image-repeat: stretch !important;
    border-image-outset: 1 !important;
    background-clip: padding-box !important;
}
.night button, .night input, .night textarea {
    border: none;
}
div#loader {
    position: absolute;
    width: 100%;
    height: calc(100% - 80px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000bb;
    font-size: 66px;
}
div#loader i.spinner.icon {
    color: white;
    animation: loading 1s linear 0s infinite;
    font-size: 64px;
    line-height: 64px;
}
@keyframes loading {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}
@media screen and (max-width: 768px){
    #mawmawmaw {
        height: auto;
        min-height: 100%;
    }
    button.ui.button.blue {
        font-size: 9px;
        line-height: 12px;
    }
    #player-controls {
        padding: 10px;
        font-size: 8px;
    }
    div#player-controls img {
        display: none;
    }
    .ui.container.main {
        margin: 5px 20px !important;
    }
}
@media screen and (min-width:768px) and (max-width: 992px){
    h1{
        font-size: 28px;
    }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 14px;
    }
    p{
        font-size: 10px;
    }
    button.ui.button.blue {
        font-size: 10px;
    }
}