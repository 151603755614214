#header {
    height: 80px;
    padding: 0;
    background-size: 100% 100%;
    box-shadow: 0 0.5rem 0.3rem 0 rgb(40 40 40 / 20%);
    filter: invert(1) opacity(0.85);
}
.night #header{
    filter: invert(0) opacity(0.75);
}
#header .secondary.menu {
    margin: 0 !important;
}
#header .right.menu {
    background-color: black;
    margin-right: 40px;
}
#header .right.menu .menu-wrapper {
    display: inherit;
}
#header .right.menu .beforeIcon, #header .right.menu .afterIcon {
    width: 14px;
    background-size: 70% 70%;
    background-position: 0 13px;
    background-repeat: no-repeat;
}
.afterIcon {
    background-position: 4px 13px !important;
}
#header .right.menu .menu-wrapper i.icon {
    width: 30px;
    font-size: 24px;
    margin: 0;
}
img.ui.image.logo {
    height: 80px;
}
@media screen and (max-width: 768px){
    #header{
        background-size: 1px 80px;
    }
    #header, img.ui.image.logo, #header .right.menu{
        height: 80px;
    }
    #header .right.menu .beforeIcon, #header .right.menu .afterIcon {
        background-size: 14px 80px;
        background-position: 0 1px;
    }
    #header .ui.secondary.menu .item {
        padding: 4px;
    }
}
@media screen and (max-width: 540px){
    #header{
        background-size: 1px 50px;
    }
    #header, img.ui.image.logo, #header .right.menu{
        height: 50px;
    }
    #header .right.menu .beforeIcon, #header .right.menu .afterIcon {
        width: 8px;
        background-size: 8px 50px;
    }
    .afterIcon {
        background-position: 0px 1px !important;
    }
    #header .ui.secondary.menu .item {
        padding: 2px;
    }
    #header .right.menu {
        margin-right: 20px !important;
    }
}
@media screen and (max-width: 400px){
    #header .right.menu {
        margin-left: auto !important;
        margin-right: 10px !important;
    }
    #header .ui.secondary.menu .item {
        padding: 0px;
    }
}