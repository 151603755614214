.parallax-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 78px;
    z-index: -1;
    overflow: hidden;
}
.parallax-layer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
div#parallax-layer-1 {
    z-index: -1;
}
div#parallax-layer-2 {
    background-repeat: repeat-x;
    background-position-x: -10px;
    animation: wind 120s linear 0s infinite;
    z-index: -2;
}
div#parallax-layer-3 {
    background-repeat: repeat-x;
    background-position-x: 0;
    animation: wind 60s linear 0s infinite;
    z-index: -3;
}
img.grass {
    position: absolute;
    z-index: 1;
    width: 80px;
}
@keyframes wind{
    0%{
        background-position-x: 0;
    }
    100%{
        background-position-x: -1920px;
    }
}

@media screen and (max-width: 768px){
    .parallax-container {
        height: calc(100% - 80px);
        margin-top: 80px;
    }
    .parallax-layer {
        background-size: 100%;
    }
}
@media screen and (max-width: 540px){
    .parallax-container {
        height: calc(100% - 50px);
        margin-top: 50px;
    }
}
@media screen and (min-width:1440px){
    .parallax-container {
        margin-top: 0px;
    }
}